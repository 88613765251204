<template lang="pug">
  main#main.manager-machines
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-conveyor-belt-alt')
      section.l-section
        .l-section-h
          router-view
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'

export default {
  name: 'manager-machines',
  components: {
    WcBreadcrumb,
  },
}
</script>
